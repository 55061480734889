import { defineStore } from "pinia";
import type { WalletProviderKind } from "~/composables/LoginManager/TypeDefs";

export type ProviderAccount = {
    address: string;
    providerKind: WalletProviderKind;
};

const providerAccounts: ProviderAccount[] = [];

export const useMainStore = defineStore("main", {
    state: () => ({
        providerAccounts: providerAccounts,
        error: "" as unknown,
        notificationUpdateVersion: 0,
    }),
    getters: {
        getProviderAccounts: (state) => state.providerAccounts,
        getError: (state) => state.error,
    },
    actions: {
        setProviderAccounts(providerAccounts: ProviderAccount[]) {
            this.providerAccounts = providerAccounts;
        },
        updateProviderAccounts(providerKind: WalletProviderKind, addresses: string[]) {
            this.providerAccounts = [
                ...addresses.map(address => ({
                    address, providerKind
                })),
                ...this.providerAccounts
                    .filter(pa => pa.providerKind !== providerKind),
            ];
        },
        setError(error: unknown) {
            this.error = error;
        },
        updateVersion() {
            this.notificationUpdateVersion++;
        }
    },
});