

import { defineNuxtPlugin } from "nuxt/app";
import { useMainStore } from "~/stores/mainStore";

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.config.errorHandler = (error, context) => {
        // ...
        if (process.client) {
            const store = useMainStore();
            store.setError(error);
            console.error(error);
        }
    };
});