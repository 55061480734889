import { default as dashboard7b1Ncpun5UMeta } from "/home/node/pages/dashboard.vue?macro=true";
import { default as indexjBvbyAgHxEMeta } from "/home/node/pages/index.vue?macro=true";
import { default as leaderboardKTQS4RqoyRMeta } from "/home/node/pages/leaderboard.vue?macro=true";
import { default as marketplacelrDTAIFnqpMeta } from "/home/node/pages/marketplace.vue?macro=true";
import { default as spine_45playgroundypbIgsaD7UMeta } from "/home/node/pages/spine-playground.vue?macro=true";
import { default as storyzRKrRYz0MkMeta } from "/home/node/pages/story.vue?macro=true";
export default [
  {
    name: dashboard7b1Ncpun5UMeta?.name ?? "dashboard",
    path: dashboard7b1Ncpun5UMeta?.path ?? "/dashboard",
    meta: dashboard7b1Ncpun5UMeta || {},
    alias: dashboard7b1Ncpun5UMeta?.alias || [],
    redirect: dashboard7b1Ncpun5UMeta?.redirect || undefined,
    component: () => import("/home/node/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: indexjBvbyAgHxEMeta?.name ?? "index",
    path: indexjBvbyAgHxEMeta?.path ?? "/",
    meta: indexjBvbyAgHxEMeta || {},
    alias: indexjBvbyAgHxEMeta?.alias || [],
    redirect: indexjBvbyAgHxEMeta?.redirect || undefined,
    component: () => import("/home/node/pages/index.vue").then(m => m.default || m)
  },
  {
    name: leaderboardKTQS4RqoyRMeta?.name ?? "leaderboard",
    path: leaderboardKTQS4RqoyRMeta?.path ?? "/leaderboard",
    meta: leaderboardKTQS4RqoyRMeta || {},
    alias: leaderboardKTQS4RqoyRMeta?.alias || [],
    redirect: leaderboardKTQS4RqoyRMeta?.redirect || undefined,
    component: () => import("/home/node/pages/leaderboard.vue").then(m => m.default || m)
  },
  {
    name: marketplacelrDTAIFnqpMeta?.name ?? "marketplace",
    path: marketplacelrDTAIFnqpMeta?.path ?? "/marketplace",
    meta: marketplacelrDTAIFnqpMeta || {},
    alias: marketplacelrDTAIFnqpMeta?.alias || [],
    redirect: marketplacelrDTAIFnqpMeta?.redirect || undefined,
    component: () => import("/home/node/pages/marketplace.vue").then(m => m.default || m)
  },
  {
    name: spine_45playgroundypbIgsaD7UMeta?.name ?? "spine-playground",
    path: spine_45playgroundypbIgsaD7UMeta?.path ?? "/spine-playground",
    meta: spine_45playgroundypbIgsaD7UMeta || {},
    alias: spine_45playgroundypbIgsaD7UMeta?.alias || [],
    redirect: spine_45playgroundypbIgsaD7UMeta?.redirect || undefined,
    component: () => import("/home/node/pages/spine-playground.vue").then(m => m.default || m)
  },
  {
    name: storyzRKrRYz0MkMeta?.name ?? "story",
    path: storyzRKrRYz0MkMeta?.path ?? "/story",
    meta: storyzRKrRYz0MkMeta || {},
    alias: storyzRKrRYz0MkMeta?.alias || [],
    redirect: storyzRKrRYz0MkMeta?.redirect || undefined,
    component: () => import("/home/node/pages/story.vue").then(m => m.default || m)
  }
]